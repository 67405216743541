@charset "utf-8";
@import url("./framework.css");
/* Rows
--------------------------------------------------------------------------------------------------------------- */

.row0, .row0 a {}

.row1 {
	border-top: 1px solid;
}

.row2, .row2 a {}

.row3 {
	border-bottom: 1px solid;
}

.row4 {
	border-bottom: 1px solid;
}

.row5, .row5 a {}

/* Header
--------------------------------------------------------------------------------------------------------------- */

#header {
	padding: 50px 0;
}

#header #logo {}

#header #logo * {
	margin: 0;
	padding: 0;
	line-height: 1;
}

#header #logo h1 {
	margin-top: -10px;
}

#header #logo p {
	font-size: .8rem;
	text-transform: capitalize;
}

#header ul {}

#header ul li {}

#header ul li div {
	position: relative;
	min-height: 45px;
	padding: 0 0 0 60px;
	line-height: 1;
}

#header ul li div i {
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 45px;
	line-height: 43px;
	font-size: 16px;
	text-align: center;
	border: 1px solid;
	border-radius: 50%;
}

#header ul li div span {
	display: block;
	padding: 4px 0 0 0;
}

#header ul li div strong {
	display: block;
	margin: 0 0 8px 0;
}

#header div:last-child {
	margin-bottom: 0;
}

/* Used when elements stack in small viewports */

/* Page Intro
--------------------------------------------------------------------------------------------------------------- */

#pageintro {
	padding: 220px 0;
}

#pageintro article {}

#pageintro .heading {
	font-size: 4rem;
	font-weight: 700;
}

#pageintro footer {
	margin-top: 50px;
}

/* Content Area
--------------------------------------------------------------------------------------------------------------- */

.container {
	padding: 80px 0;
}

/* Content */

.container .content {}

.sectiontitle {
	display: block;
	max-width: 55%;
	margin: 0 auto 80px;
	text-align: center;
}

.sectiontitle * {
	margin: 0;
}

/* Latest */

.latest {}

.latest>li:last-child {
	margin-bottom: 0;
}

/* Used when elements stack in small viewports */

.latest article {}

.latest article time {
	display: block;
	margin: 0 0 20px 0;
	padding: 0;
	font-style: normal;
	font-size: .8rem;
	line-height: 1;
}

.latest article .excerpt .meta {
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
	text-align: left;
}

.latest article .excerpt .meta li {
	display: inline-block;
	font-size: .8rem;
}

.latest article .excerpt .meta li::after {
	margin-left: 5px;
	content: "|";
}

.latest article .excerpt .meta li:last-child::after {
	margin: 0;
	content: "";
}

/* Comments */

#comments ul {
	margin: 0 0 40px 0;
	padding: 0;
	list-style: none;
}

#comments li {
	margin: 0 0 10px 0;
	padding: 15px;
}

#comments .avatar {
	float: right;
	margin: 0 0 10px 10px;
	padding: 3px;
	border: 1px solid;
}

#comments address {
	font-weight: bold;
}

#comments time {
	font-size: smaller;
}

#comments .comcont {
	display: block;
	margin: 0;
	padding: 0;
}

#comments .comcont p {
	margin: 10px 5px 10px 0;
	padding: 0;
}

#comments form {
	display: block;
	width: 100%;
}

#comments input, #comments textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid;
}

#comments textarea {
	overflow: auto;
}

#comments div {
	margin-bottom: 15px;
}

#comments input[type="submit"], #comments input[type="reset"] {
	display: inline-block;
	width: auto;
	min-width: 150px;
	margin: 0;
	padding: 8px 5px;
	cursor: pointer;
}

/* Sidebar */

.container .sidebar {}

.sidebar .sdb_holder {
	margin-bottom: 50px;
}

.sidebar .sdb_holder:last-child {
	margin-bottom: 0;
}

/* Shout
--------------------------------------------------------------------------------------------------------------- */

#shout {
	text-align: center;
}

#shout * {
	margin: 0;
	padding: 0;
}

#shout article {
	display: block;
	max-width: 75%;
	margin: 0 auto;
}

#shout article .heading {
	margin-bottom: 20px;
}

/* Services
--------------------------------------------------------------------------------------------------------------- */

#services {
	text-align: center;
}

#services li {
	margin-bottom: 30px;
	padding: 25px 0 0 0;
}

#services li:nth-last-child(-n+3) {
	margin-bottom: 0;
}

/* Removes bottom margin from the last four items - margin is restored in the media queries when items stack */

#services li:nth-child(3n+1) {
	margin-left: 0;
	clear: left;
}

/* Removes the need to add class="first" */

#services li article {
	display: block;
	position: relative;
	padding: 40px 25px 30px;
}

#services li article * {
	margin: 0;
	padding: 0;
}

#services li article>a>i {
	display: block;
	position: absolute;
	top: -25px;
	left: 10px;
	width: 50px;
	height: 50px;
	line-height: 48px;
	border: 1px solid;
	border-radius: 50%;
	font-size: 1.8rem;
	z-index: 0;
}

#services li article .heading {
	margin-bottom: 15px;
	font-weight: 700;
}

#services li article footer {
	margin-top: 20px;
	font-size: 2.5rem;
}

/* Click to Action
--------------------------------------------------------------------------------------------------------------- */

#cta {}

#cta> :first-child {
	margin-top: 10px;
	font-weight: 700;
	text-transform: uppercase;
}

#cta> :last-child {
	margin-bottom: 0;
}

/* Used when elements stack in smaller viewports */

#cta footer {
	text-align: center;
}

#cta footer a {
	display: block;
}

/* Team
--------------------------------------------------------------------------------------------------------------- */

#team {}

#team li:last-child {
	margin-bottom: 0;
}

/* Used when elements stack in small viewports */

#team article {
	display: block;
	text-align: center;
	padding: 35px 25px;
}

#team article * {
	margin: 0;
	padding: 0;
}

#team article figure {}

#team article figure a {
	margin-bottom: 35px;
}

#team article figure .imgover::before, #team article figure img {
	border-radius: 50%;
}

#team article figure figcaption {
	line-height: 1;
}

#team article em {
	display: block;
	margin-bottom: 25px;
	font-style: normal;
	font-size: .8rem;
}

#team article p {}

#team article footer {
	margin-top: 35px;
}

#team article footer .faico {
	display: block;
	text-align: center;
}

#team article footer .faico li {
	display: inline-block;
	margin: 0 12px 0 0;
}

#team article footer .faico a {
	display: block;
	width: auto;
	height: auto;
	line-height: 1;
	font-size: 1.2rem;
}

/* Testimonials
--------------------------------------------------------------------------------------------------------------- */

#testimonials {}

#testimonials li:last-child {
	margin-bottom: 0;
}

/* Used when elements stack in smaller viewports */

#testimonials blockquote {
	display: block;
	position: relative;
	width: 100%;
	margin: 0 0 30px 0;
	padding: 20px;
	line-height: 1.4;
	z-index: 0;
}

#testimonials blockquote::before {
	display: block;
	position: absolute;
	top: 10px;
	left: 10px;
	font-family: "Font Awesome\ 5 Free";
	font-weight: 900;
	font-size: 32px;
	line-height: 32px;
	content: "\f10d";
	z-index: -1;
}

#testimonials blockquote::after {
	display: block;
	position: absolute;
	bottom: -15px;
	left: 30px;
	width: 0;
	height: 0;
	content: "";
	border: 15px solid transparent;
	border-bottom: none;
}

#testimonials figure {}

#testimonials figure * {
	margin: 0;
	line-height: 1;
}

#testimonials figure img {
	float: left;
	margin: 0 15px;
}

#testimonials figcaption {
	float: left;
	margin-top: 10px;
}

#testimonials figcaption .heading {
	font-size: 1.2rem;
	font-weight: 700;
}

#testimonials figcaption em {
	font-size: .8rem;
}

#testimonials footer {
	text-align: center;
}

/* Copyright
--------------------------------------------------------------------------------------------------------------- */

#copyright {
	padding: 20px 0;
}

#copyright * {
	margin: 0;
	padding: 0;
}

/* Transition Fade
This gives a smooth transition to "ALL" elements used in the layout - other than the navigation form used in mobile devices
If you don't want it to fade all elements, you have to list the ones you want to be faded individually
Delete it completely to stop fading
--------------------------------------------------------------------------------------------------------------- */

*, *::before, *::after {
	transition: all .1s ease-in-out;
}

#mainav form * {
	transition: none !important;
}

/* Navigation
--------------------------------------------------------------------------------------------------------------- */

/* Breadcrumb */

#breadcrumb {
	padding: 150px 0 30px;
}

#breadcrumb ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-transform: uppercase;
}

#breadcrumb li {
	display: inline-block;
	margin: 0 6px 0 0;
	padding: 0;
}

#breadcrumb li a {
	display: block;
	position: relative;
	margin: 0;
	padding: 0 12px 0 0;
	font-size: 12px;
}

#breadcrumb li a::after {
	top: 4px;
	right: 0;
	content: "\f0da";
}

#breadcrumb li:last-child a {
	margin: 0;
	padding: 0;
}

#breadcrumb li:last-child a::after {
	display: none;
}

#breadcrumb .heading {
	margin: 0;
	font-size: 2rem;
}

/* Sidebar Navigation */

.sidebar nav {
	display: block;
	width: 100%;
}

.sidebar nav li {
	margin: 0 0 3px 0;
	padding: 0;
}

.sidebar nav a {
	display: block;
	position: relative;
	margin: 0;
	padding: 5px 10px 5px 15px;
	text-decoration: none;
	border: solid;
	border-width: 0 0 1px 0;
}

.sidebar nav a::after {
	top: 10px;
	left: 5px;
	content: "\f0da";
}

.sidebar nav ul ul a {
	padding-left: 35px;
}

.sidebar nav ul ul a::after {
	left: 25px;
}

.sidebar nav ul ul ul a {
	padding-left: 55px;
}

.sidebar nav ul ul ul a::after {
	left: 45px;
}

/* Pagination */

.pagination {
	display: block;
	width: 100%;
	text-align: center;
	clear: both;
}

.pagination li {
	display: inline-block;
	margin: 0 2px 0 0;
}

.pagination li:last-child {
	margin-right: 0;
}

.pagination a, .pagination strong {
	display: block;
	padding: 8px 11px;
	border: 1px solid;
	background-clip: padding-box;
	font-weight: normal;
}

/* Back to Top */

#backtotop {
	z-index: 0;
	display: inline-block;
	position: fixed;
	visibility: hidden;
	bottom: 20px;
	right: 20px;
	width: 36px;
	height: 36px;
	line-height: 36px;
	font-size: 16px;
	text-align: center;
	opacity: .2;
}

#backtotop i {
	display: block;
	width: 100%;
	height: 100%;
	line-height: inherit;
}

#backtotop.visible {
	visibility: visible;
	opacity: .5;
}

#backtotop:hover {
	opacity: 1;
}

/* Tables
--------------------------------------------------------------------------------------------------------------- */

table, th, td {
	border: 1px solid;
	border-collapse: collapse;
	vertical-align: top;
}

table, th {
	table-layout: auto;
}

table {
	width: 100%;
	margin-bottom: 15px;
}

th, td {
	padding: 5px 8px;
}

td {
	border-width: 0 1px;
}

/* Gallery
--------------------------------------------------------------------------------------------------------------- */

#gallery {
	display: block;
	width: 100%;
	margin-bottom: 50px;
}

#gallery figure figcaption {
	display: block;
	width: 100%;
	clear: both;
}

#gallery li {
	margin-bottom: 30px;
}

/* Font Awesome Social Icons
--------------------------------------------------------------------------------------------------------------- */

.faico {
	margin: 0;
	padding: 0;
	list-style: none;
}

.faico li {
	display: inline-block;
	margin: 8px 5px 0 0;
	padding: 0;
	line-height: normal;
}

.faico li:last-child {
	margin-right: 0;
}

.faico a {
	display: inline-block;
	width: 36px;
	height: 36px;
	line-height: 36px;
	font-size: 18px;
	text-align: center;
}

.faico a {
	color: #474747;
	background-color: #A8A8A8;
}

.faico a:hover {
	color: #FFFFFF;
}

.faicon-dribble:hover {
	background-color: #EA4C89;
}

.faicon-facebook:hover {
	background-color: #3B5998;
}

.faicon-google-plus:hover {
	background-color: #DB4A39;
}

.faicon-linkedin:hover {
	background-color: #0E76A8;
}

.faicon-insta:hover {
	background-color: #8a3ab9;
}

.faicon-twitter:hover {
	background-color: #00ACEE;
}

.faicon-vk:hover {
	background-color: #4E658E;
}

/* ------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------ */

/* Colours
--------------------------------------------------------------------------------------------------------------- */

body {
	color: #A8A8A8;
	background-color: #151618;
}

a {
	color: #82B7DF;
}

a:active, a:focus {
	background: transparent;
}

/* IE10 + 11 Bugfix - prevents grey background */

hr, .borderedbox {
	border-color: #D7D7D7;
}

label span {
	color: #FF0000;
	background-color: inherit;
}

input:focus, textarea:focus, *:required:focus {
	border-color: #82B7DF;
}

.overlay {
	color: #FFFFFF;
	background-color: inherit;
}

.overlay::after {
	color: inherit;
	background-color: rgba(0, 0, 0, .55);
}

.overlay.dark::after {
	color: inherit;
	background-color: rgba(37, 100, 146, .85)/* #256492 */
	;
}

.overlay.light::after {
	background-color: rgba(130, 183, 223, .65)/* #82B7DF */
	;
}

.btn, .btn.inverse:hover {
	color: #FFFFFF;
	background-color: #82B7DF;
	border-color: #82B7DF;
}

.btn:hover, .btn.inverse {
	color: inherit;
	background-color: transparent;
	border-color: inherit;
}

.imgover:hover::before {
	background: rgba(37, 100, 146, .35)/* #256492 */
	;
}

.imgover, .imgover:hover::after {
	color: #FFFFFF;
}

.heading.underline::after {
	background-color: #256492;
}

.logoname {
	color: inherit;
}

.logoname i {
	color: #256492;
}

/* Rows */

.row0 {
	color: #474747;
	background-color: #FFFFFF;
}

.row1 {
	color: #474747;
	background-color: #FFFFFF;
	border-color: rgba(0, 0, 0, .1);
}

.row2 {
	color: #474747;
	background-color: #F4F4F4;
}

.row3 {
	color: #474747;
	background-color: #FFFFFF;
	border-color: rgba(0, 0, 0, .1);
}

.row4 {
	color: #A8A8A8;
	background-color: #1C1D1F;
	border-color: rgba(255, 255, 255, .1);
}

.row5, .row5 a {
	color: #A8A8A8;
	background-color: #151618;
}

/* convenios */

#services li article {
	color: #13f195;
	background-color: #e2e2e2;
}

#services li:nth-child(even) article {
	background-color: #f3f4f5;
}

#services li article a {
	color: inherit;
}

#services li article>a>i {
	color: #474747;
	background: #FFFFFF;
	border-color: rgba(0, 0, 0, .1);
	box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, .3);
}

#services li article:hover>a>i {
	color: #82B7DF;
}

#services li:nth-child(odd) article:hover footer i {
	color: #256492;
}

#services li:nth-child(even) article:hover footer i {
	color: #82B7DF;
}

/* Click to Action */

#cta .btn {
	color: #256492;
	background-color: #FFFFFF;
	border-color: #256492;
}

#cta .btn:hover {
	color: inherit;
	background-color: transparent;
	border-color: inherit;
}

/* Team */

#team article {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, .1);
}

#team article:hover {
	color: inherit;
	background-color: #F4F4F4;
}

#team article footer .faico a {
	color: inherit;
	background: transparent;
}

#team article footer .faico .faicon-dribble:hover {
	color: #EA4C89;
}

#team article footer .faico .faicon-facebook:hover {
	color: #3B5998;
}

#team article footer .faico .faicon-google-plus:hover {
	color: #DB4A39;
}

#team article footer .faico .faicon-linkedin:hover {
	color: #0E76A8;
}

#team article footer .faico .faicon-twitter:hover {
	color: #00ACEE;
}

#team article footer .faico .faicon-vk:hover {
	color: #4E658E;
}

/* Testimonials */

#testimonials blockquote {
	color: inherit;
	background-color: #F4F4F4;
}

#testimonials blockquote::before {
	color: #FFFFFF;
}

#testimonials blockquote::after {
	border-top-color: #F4F4F4;
}

/* Footer */

#footer .heading, #footer .logoname a {
	color: #FFFFFF;
}

#footer hr, #footer .borderedbox, #footer .linklist li {
	border-color: rgba(255, 255, 255, .1);
}

/* Navigation */

#mainav {}

#mainav li a {
	color: inherit;
	border-color: transparent;
}

#mainav .active a, #mainav a:hover, #mainav li:hover>a {
	color: #82B7DF;
	background-color: #FFFFFF;
	border-color: #82B7DF;
}

#mainav li li a, #mainav .active li a {
	color: #474747;
	background-color: rgba(255, 255, 255, .8);
	border-color: #FFFFFF;
}

#mainav li li:hover>a, #mainav .active .active>a {
	color: #82B7DF;
	background-color: #FFFFFF;
	border-color: #82B7DF;
}

#mainav form select {
	color: #474747;
	background-color: #FFFFFF;
	border-color: #82B7DF;
}

#breadcrumb a {
	color: inherit;
	background-color: inherit;
}

#breadcrumb li:last-child a {
	color: #82B7DF;
}

.container .sidebar nav a {
	color: inherit;
	border-color: #D7D7D7;
}

.container .sidebar nav a:hover {
	color: #82B7DF;
}

.pagination a, .pagination strong {
	border-color: #D7D7D7;
}

.pagination .current * {
	color: #FFFFFF;
	background-color: #82B7DF;
}

#backtotop {
	color: #FFFFFF;
	background-color: #82B7DF;
}

/* Tables + Comments */

table, th, td, #comments .avatar, #comments input, #comments textarea {
	border-color: #D7D7D7;
}

#comments input:focus, #comments textarea:focus, #comments *:required:focus {
	border-color: #82B7DF;
}

th {
	color: #FFFFFF;
	background-color: #373737;
}

tr, #comments li, #comments input[type="submit"], #comments input[type="reset"] {
	color: inherit;
	background-color: #FBFBFB;
}

tr:nth-child(even), #comments li:nth-child(even) {
	color: inherit;
	background-color: #F7F7F7;
}

table a, #comments a {
	background-color: inherit;
}

/* Media Queries
--------------------------------------------------------------------------------------------------------------- */

@-ms-viewport {
	width: device-width;
}

/* Max Wrapper Width - Laptop, Desktop etc.
--------------------------------------------------------------------------------------------------------------- */

@media screen and (min-width:1140px) {
	.hoc {
		max-width: 1140px;
	}
}

@media screen and (min-width:978px) and (max-width:1140px) {
	.hoc {
		max-width: 95%;
	}
}

/* Mobile Devices
--------------------------------------------------------------------------------------------------------------- */

@media screen and (max-width:978px) {
	.hoc {
		max-width: 90%;
	}
	#header {}
	#mainav {
		position: inherit;
		margin-top: 0;
		padding: 30px 0;
	}
	#mainav form {
		display: block;
	}
	#breadcrumb {}
	.container {}
	#comments input[type="reset"] {
		margin-top: 10px;
	}
	.pagination li {
		display: inline-block;
		margin: 0 5px 5px 0;
	}
	#footer {}
	#copyright {}
	#copyright p:first-of-type {
		margin-bottom: 10px;
	}
}

@media screen and (max-width:790px) {
	.imgl, .imgr {
		display: inline-block;
		float: none;
		margin: 0 0 10px 0;
	}
	.fl_left, .fl_right {
		display: block;
		float: none;
	}
	.group .group>*:last-child, .clear .clear>*:last-child, .clear .group>*:last-child, .group .clear>*:last-child {
		margin-bottom: 0;
	}
	/* Experimental - Needs more testing in different situations, stops double margin when stacking */
	.one_half, .one_third, .two_third, .one_quarter, .two_quarter, .three_quarter {
		display: block;
		float: none;
		width: auto;
		margin: 0 0 30px 0;
		padding: 0;
	}
	#header {}
	#pageintro {
		padding: 150px 0;
	}
	#pageintro .heading {
		font-size: 2rem;
	}
	.sectiontitle {
		max-width: none;
	}
	#shout article {
		max-width: none;
		margin: auto;
	}
	#shout article .heading {
		font-size: 2rem;
	}
	#services li:nth-last-child(-n+3) {
		margin-bottom: 30px;
	}
	#cta> :first-child {
		margin-top: 0;
		text-align: center;
	}
	#cta footer a {
		display: inline-block;
	}
	#footer {
		padding-bottom: 50px;
	}
	/* Not required, just looks a little better */
}

/* Other
--------------------------------------------------------------------------------------------------------------- */

@media screen and (max-width:650px) {
	.scrollable {
		display: block;
		width: 100%;
		margin: 0 0 30px 0;
		padding: 0 0 15px 0;
		overflow: auto;
		overflow-x: scroll;
	}
	.scrollable table {
		margin: 0;
		padding: 0;
		white-space: nowrap;
	}
	.inline li {
		display: block;
		margin-bottom: 10px;
	}
	.pushright li {
		margin-right: 0;
	}
	.font-x2 {
		font-size: 1.6rem;
	}
	.font-x3 {
		font-size: 1.8rem;
	}
}

/* Custom
--------------------------------------------------------------------------------------------------------------- */

.alignFlexButton {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.messageInfo {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.messageType {
	padding-bottom: 10px;
	font-size: 12px;
}

.miniDate {
	font-size: 12px;
	font-weight: 700;
}

.loadingImg {
	height: 200px;
	width: 200px;
}

.flexColumn {
	display: flex;
	flex-direction: column;
	align-items: center;
}