@charset "utf-8";
html {
 overflow-y: scroll;
 overflow-x: hidden;
}

html, body {
 margin: 0;
 padding: 0;
 font-size: 14px;
 line-height: 1.6em;
}

*, *::before, *::after {
 box-sizing: border-box;
}

.bold {
 font-weight: bold;
}

.center {
 text-align: center;
}

.right {
 text-align: right;
}

.uppercase {
 text-transform: uppercase;
}

.capitalise {
 text-transform: capitalize;
}

.hidden {
 display: none;
}

.left-space15 {
 margin-left: 15%;
 padding: 0;
 list-style: none;
}

.nospace {
 margin: 0;
 padding: 0;
 list-style: none;
}

.block {
 display: block;
}

.justified {
 text-align: justify;
}

.inline * {
 display: inline-block;
}

.inline *:last-child {
 margin-right: 0;
}

.pushright li {
 margin-right: 20px;
}

.pushright li:last-child {
 margin-right: 0;
}

.borderedbox {
 border: 1px solid;
}

.overlay {
 position: relative;
 z-index: 0;
}

.overlay::after {
 display: block;
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 content: "";
 z-index: -1;
}

.bgded {
 background-position: top center;
 background-repeat: no-repeat;
 background-size: cover;
}

.circle {
 border-radius: 50%;
 background-clip: padding-box;
}

.btn {
 display: inline-block;
 padding: 8px 18px 10px;
 text-transform: uppercase;
 border: 1px solid;
}

.logoname {
 display: inline-block;
 min-width: 170px;
 font-size: 32px;
 font-variant: small-caps;
 font-weight: 700;
}

.logoname i, .logoname span {
 position: relative;
 float: left;
}

.logoname i {
 font-size: 3.5rem;
 z-index: 1;
}

.logoname span {
 margin: 10px 0 0 -5px!important;
}

.clear, .group {
 display: block;
}

.clear::before, .clear::after, .group::before, .group::after {
 display: table;
 content: "";
}

.clear, .clear::after, .group, .group::after {
 clear: both;
}

a {
 outline: none;
 text-decoration: none;
}

.fl_left, .imgl {
 float: left;
}

.fl_right, .imgr {
 float: right;
}

.flexAlignImages {
 display: flex;
 flex-direction: row;
}

img {
 width: auto;
 max-width: 100%;
 height: auto;
 margin: 0;
 padding: 0;
 border: none;
 line-height: normal;
 vertical-align: middle;
}

.imgl {
 margin: 0 15px 10px 0;
 clear: left;
}

.imgr {
 margin: 0 0 10px 15px;
 clear: right;
}

.imgover {
 display: inline-block;
 position: relative;
 max-width: 70%;
}

.imgover50 {
 display: inline-block;
 position: relative;
 max-width: 60%;
}

/* Fonts
--------------------------------------------------------------------------------------------------------------- */

body, input, textarea, select {
 font-family: Verdana, Geneva, sans-serif;
}

h1, h2, h3, h4, h5, h6, .heading {
 font-family: Georgia, "Times New Roman", Times, serif;
}

/* Forms
--------------------------------------------------------------------------------------------------------------- */

form, fieldset, legend {
 margin: 0;
 padding: 0;
 border: none;
}

legend {
 display: none;
}

label, input, textarea, select, button {
 display: block;
 resize: none;
 outline: none;
 color: inherit;
 font-size: inherit;
 font-family: inherit;
 vertical-align: middle;
}

label {
 margin-bottom: 5px;
}

:required, :invalid {
 outline: none;
 box-shadow: none;
}

::placeholder {
 opacity: 1;
}

/* Makes sure the placeholder text is not transparent */

/* Generalise
--------------------------------------------------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6, .heading {
 margin: 0 0 20px 0;
 font-size: 1.4rem;
 line-height: normal;
 font-weight: normal;
}

.heading-text {
 font-size: 5rem;
}

.heading-text-sub {
 font-size: 3rem;
}

.heading.nospace {
 margin-bottom: 0;
}

.heading.underline {
 display: inline-block;
 position: relative;
 padding: 0 0 15px 0;
}

.heading.underline::after {
 display: block;
 position: absolute;
 bottom: 0;
 width: 25%;
 height: 5px;
 border-radius: 3px;
 content: "";
}

.center .heading.underline::after {
 left: 50%;
 margin-left: -12%;
}

address {
 font-style: normal;
 font-weight: normal;
}

hr {
 display: block;
 width: 100%;
 height: 1px;
 border: solid;
 border-width: 1px 0 0 0;
}

.font-xs {
 font-size: 1rem;
}

.font-x2 {
 font-size: 2.2rem;
}

.font-x3 {
 font-size: 3.2rem;
}

.wrapper {
 display: block;
 position: relative;
 width: 100%;
 margin: 0;
 padding: 0;
 text-align: left;
 word-wrap: break-word;
}

/*
The "hoc" class is a generic class used to centre a containing element horizontally
It should be used in conjunction with a second class or ID
*/

.hoc {
 display: block;
 margin: 0 auto;
}

/* HTML 5 Overrides
--------------------------------------------------------------------------------------------------------------- */

address, article, aside, figcaption, figure, footer, header, main, nav, section {
 display: block;
 margin: 0;
 padding: 0;
}

/* Grid - RS-MQF 1140 V.2 - https://www.os-templates.com/free-basic-html5-templates/rs-mqf-1140
--------------------------------------------------------------------------------------------------------------- */

.one_half, .one_third, .two_third, .one_quarter, .two_quarter, .three_quarter {
 display: inline-block;
 float: left;
 margin: 0 0 0 4.21052%;
 list-style: none;
}

.first {
 margin-left: 0;
 clear: left;
}

.one_quarter {
 width: 21.8421%;
}

.one_third {
 width: 30.52631%;
}

.one_half, .two_quarter {
 width: 47.89473%;
}

.two_third {
 width: 65.26315%;
}

.three_quarter {
 width: 73.94736%;
}

/* Spacing
--------------------------------------------------------------------------------------------------------------- */

.btmspace-10 {
 margin-bottom: 10px;
}

.btmspace-15 {
 margin-bottom: 15px;
}

.btmspace-30 {
 margin-bottom: 30px;
}

.btmspace-50 {
 margin-bottom: 50px;
}

.btmspace-80 {
 margin-bottom: 80px;
}

.rgtspace-5 {
 margin-right: 5px;
}

.rgtspace-10 {
 margin-right: 10px;
}

.rgtspace-15 {
 margin-right: 15px;
}

.rgtspace-30 {
 margin-right: 30px;
}

.rgtspace-50 {
 margin-right: 50px;
}

.rgtspace-80 {
 margin-right: 80px;
}

.inspace-5 {
 padding: 5px;
}

.inspace-10 {
 padding: 10px;
}

.inspace-15 {
 padding: 15px;
}

.inspace-30 {
 padding: 30px;
}

.inspace-50 {
 padding: 50px;
}

.inspace-80 {
 padding: 80px;
}

.top-spacing40{
 padding-top: 40px ;
}

