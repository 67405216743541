:root {
 --cor-fundo: rgb(255, 255, 255);
 --cor-hover: #82B7DF;
 --cor-primaria: rgb(0, 0, 0);
}

nav ul, nav ol {
 margin: 0;
 padding: 0;
 list-style: none;
}

button {
 font-size: 1rem;
 color: rgb(0, 0, 0);
 border: 0;
 padding: 0 2rem;
 background: var(--cor-fundo);
 border-radius: 0.25rem;
 height: 3rem;
}

button:hover {
 font-size: 1rem;
 color: #000000;
 background-color: var(--cor-hover);
 border: 0;
 padding: 0 2rem;
 border-radius: 0.25rem;
 height: 3rem;
}

.dp-menu ul {
 display: flex;
 flex-direction: row;
 list-style-type: none;
 padding: 0;
 z-index: 99999;
}

.dp-menu ul {
 background-color: var(--cor-fundo);
}

.dp-menu ul .border {
 border: solid 1px rgb(41, 41, 41);
 width: 190px;
}

.dp-menu ul li {
 display: inline;
 position: relative;
}

.dp-menu ul li a {
 color: var(--cor-primaria);
 text-decoration: none;
 display: inline-block;
 padding: 10px;
 transition: background .3s;
}

.dp-menu ul li a:hover {
 background-color: var(--cor-hover);
}

/*sub menu*/

.dp-menu ul ul {
 display: none;
 left: 0;
 position: absolute;
}

.dp-menu ul li:hover ul {
 display: block;
}

.dp-menu ul ul {
 width: 150px
}

.dp-menu ul ul li a {
 display: block;
}