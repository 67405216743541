#header {
	padding: 50px 0;
}

#header #logo {
	margin: 0;
	padding: 0;
	line-height: 1;
}

#header #logo h1 {
	margin-top: -10px;
}

#header #logo p {
	font-size: .8rem;
	text-transform: capitalize;
}

#header ul {}

#header ul li {}

#header ul li div {
	position: relative;
	min-height: 45px;
	padding: 0 0 0 60px;
	line-height: 1;
}

#header ul li div i {
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 45px;
	line-height: 43px;
	font-size: 16px;
	text-align: center;
	border: 1px solid;
	border-radius: 50%;
}

#header ul li div span {
	display: block;
	padding: 4px 0 0 0;
}

#header ul li div strong {
	display: block;
	margin: 0 0 8px 0;
}

#header div:last-child {
	margin-bottom: 0;
}

#header #logo .logoname a {
	color: inherit;
}

#header ul li div i {
	border-color: rgba(0, 0, 0, .1);
}

/* Page Intro */

#pageintro {
	color: #FFFFFF;
}